import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Post from "../components/post"
import PaginationLinks from "../components/pagination"


import { graphql, useStaticQuery } from "gatsby"

const IndexPage = () => {
  const postsPerPage = 4
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }
      limit: 4) {
        totalCount
        edges {
          node {
            id
            frontmatter {
              title
              date(formatString: "MMM Do YYYY")
              author
              tags
              image {
                childImageSharp {
                  fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            excerpt
            fields {
              slug
            }
          }
        }
      }
    }
  `)
  
  let numberOfPages = Math.ceil(data.allMarkdownRemark.totalCount / postsPerPage)

  return (
    <>
      <Layout pageTitle="Home">
        <SEO title="Home" />

        <div>
          {data.allMarkdownRemark.edges.map(({ node }) => {
            return (
              <Post
                key={node.id}
                title={node.frontmatter.title}
                date={node.frontmatter.date}
                author={node.frontmatter.author}
                slug={node.fields.slug}
                body={node.excerpt}
                fluid={node.frontmatter.image.childImageSharp.fluid}
                tags={node.frontmatter.tags}
              />
            )
          })}

        </div>
        
        <PaginationLinks pageUrl={''} currentPage ={1} numberOfPages={numberOfPages}/>
      </Layout>
    </>
  )
}

export default IndexPage
